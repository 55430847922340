import React from "react";

export const Apphostingguide = (props) => {
  return (
    <div id="apphostingguide">
      <div className="container">
        <div className="row">


          <div className="col-xs-12 col-md-6">
            <div className="apphostingguide-text">
              <h2>App Hosting Guide</h2>



              <p>{props.data ? props.data.paragraph : "loading..."}</p>

              {/* <p><span className='wholesaleprice'>$29.99</span>  <span className='discountedprice'>$19.99 <span>until April 30th</span></span></p> */}
              <p >$29.99</p>

              <div className="apphostingguide-buy">
              <div id='product-component-1713275909538'></div>
              </div>

              <h3>Benefits</h3>
              <div className="list-style">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                        <li key={`${d}-${i}`}>{d}</li>
                      ))
                      : "loading"}
                  </ul>
                </div>
                {/* <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/AppHostingGuidePreview.png" className="img-responsive" alt="" />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
